import './App.css';
import MetamaskLogin from './MetamaskLogin';
import { addStakingInfo, getStakingInfo, updateFirstWholeStakingShow, updateNavMobileButton, updateStakingButton, updateStakingForm } from './utils'
import ReactDOM from 'react-dom/client';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider
} from '@web3modal/ethers/react'
import { useEffect } from 'react';
import { ethers } from 'ethers';
import { BrowserProvider } from 'ethers'

// 1. Get projectId at https://cloud.walletconnect.com
let projectId = 'b38459dd754d235db4af60687e1f1162'

// 2. Set chains
let mainnet = {
  chainId: 56,
  name: 'Smart Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed.binance.org/'
}

// 3. Create modal
let metadata = {
  name: 'presicex',
  description: 'Staked USDT',
  url: 'https://presicex.com/',
  icons: ['https://stkusdt.com/static/media/stUsdt.90084258ad3fdb3bcc90ae0ee116159d.svg']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId
})

let modal = useWeb3Modal()

export async function handleNavButton(isConnected, address, walletProvider){
  let homeButton = document.querySelector("#drawer-menu > a:nth-child(1)")
  if (!homeButton) {
    homeButton = document.querySelector("#header > div > div.header-route > a:nth-child(1)")
  }

  // add event listener to home button
  if (homeButton) {
    homeButton.addEventListener("click", async (e) => {
      update()
    })

    homeButton.style.pointerEvents = "initial"
  }

  let stakingButton = document.querySelector("#drawer-menu > a:nth-child(2)")
  if (!stakingButton) {
    stakingButton = document.querySelector("#header > div > div.header-route > a:nth-child(2)")
  }
  // add event listener to staking button
  if (stakingButton) {
    stakingButton.addEventListener("click", async (e) => {
      update(isConnected, address, walletProvider)
    })

    stakingButton.style.pointerEvents = "initial"
  }

  let homeStakingButton = document.querySelector(".home-staked-btn")
  console.log("homeStakingBUtton", homeStakingButton)
  if (homeStakingButton) {
    homeStakingButton.addEventListener("click", async (e) => {
      update(isConnected, address, walletProvider)
    })

    homeStakingButton.style.pointerEvents = "initial"
  }
}



let update = async (isConnected, address, walletProvider) => {
  console.log("check")
    // both page
    let header = document.querySelector("#header")
    console.log("header", header)
    while (!header) {
      console.log("waiting for header")
      await new Promise(r => setTimeout(r, 20))
      header = document.querySelector("#header") || document.querySelector("#mobile-header") || document.querySelector(".main")
    }
  
    if (document.querySelector("#mobile-header")){
      await updateNavMobileButton(address, isConnected, modal).then(()=>{
        handleNavButton(isConnected, address, walletProvider)
      })
    }    

    
  // add display: block !important to the drawer

  await new Promise(r => setTimeout(r, 100))

  let connectBtn = document.querySelector(".header-wallet")
  console.log("connectBtn", connectBtn)
  if (connectBtn) {
    if ((!isConnected || !address)) {
      connectBtn.innerText = "Connect"
    } else {
      connectBtn.innerText =
        `${address.slice(0, 5)}...${address.slice(-4)}`
    }

    let newConnectButton = connectBtn.cloneNode(true)
    connectBtn.parentNode.replaceChild(newConnectButton, connectBtn)

    newConnectButton.addEventListener("click", (e) => {
      let closeButton = document.querySelector("body > div:nth-child(6) > div > div.ant-drawer-content-wrapper > div > div > div.ant-drawer-header.ant-drawer-header-close-only > div > button")
      if (closeButton) {
        closeButton.click()
      }

      modal.open().then((provider) => {
      })
    })
  }

  // update general
  let logo = document.querySelector("#header > div > div.header-icon")
  if (logo) {
    logo.addEventListener("click", () => {
      window.location.href = "/"
    })
  }

  // if in staking page
  if (window.location.href.includes("/stake")) {
    let stakingPage = document.querySelector("#root > div > div.pr > div.main-content > div > div:nth-child(1) > div > div.home-datas-list > div:nth-child(1) > div:nth-child(1)")
    while (!stakingPage) {
      console.log("waiting for staking page")
      await new Promise(r => setTimeout(r, 200))
      stakingPage = document.querySelector("#root > div > div.pr > div.main-content > div > div:nth-child(1) > div > div.home-datas-list > div:nth-child(1) > div:nth-child(1)")
    }

    await Promise.all([
      addStakingInfo(address, isConnected, walletProvider),
      updateFirstWholeStakingShow(address, isConnected, walletProvider, modal),
      updateStakingForm(),
      updateStakingButton(isConnected, walletProvider, address, modal)
    ])

  }
}

function App() {
let { isConnected, address } = useWeb3ModalAccount()
  let { walletProvider } = useWeb3ModalProvider()

  // temp(isConnected, walletProvider)

  update(isConnected, address, walletProvider)

  useEffect(() => {
    update(isConnected, address, walletProvider)
  }, [isConnected, address])

  return (
    <div className="App">
    </div>
  );
}

export default App;
